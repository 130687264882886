 
  
  .flex {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .app {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 10px 10px 10px;
  }
  
  .padding-20 {
    padding: 20px;
  }
  
  .margin-top-10 {
    margin-top: 10px;
  }
  
  .input-position {
    width: 100%;
    position: relative;
  }
  
  .thumbnail img {
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px;  /* Rounded border */
    padding: 5px; /* Some padding */
    width: 100px; /* Set a small width */
  }
  
  /* Add a hover effect (blue shadow) */
  .thumbnail img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }