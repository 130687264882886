/* colour theme settings */
:root {

    /* Body background and text color */
    --surface-color: rgb(255, 255, 255); 
    --on-surface-color: rgb(32, 26, 25); 
  
    /* Primary color */
    --primary-color: rgb(219, 42, 66); 
    --on-primary-color: rgb(255, 255, 255); 
    --primary-color-opacity-35: rgba(219, 42, 66, 0.35);
    --primary-color-opacity-85: rgba(219, 42, 66, 0.85);
  
    /* Text/Bg neutral colors */
    --neutral-color-1: rgb(51, 51, 51);
    --neutral-color-2: rgb(69, 69, 69);
    --neutral-color-3: rgb(88, 88, 88);
    --neutral-color-4: rgb(115, 115, 115);
    --neutral-color-5: rgb(195, 195, 195);
    --neutral-color-6: rgb(241, 241, 241);
  
    /* Button primary */ 
    --button-primary-color: rgb(219, 42, 66);
    --button-on-primary-text-color: rgb(255, 255, 255);
    --button-primary-hover-color: rgba(219, 42, 66, 0.85);
  
     /* Border color */
     --border-color-1: rgb(232, 232, 232);
  
    /* Hero area */
    --hero-transparent-bg: rgba(0,0,0, 0.35);
  
    /* Box shadows*/
    --box-shadow-1: 0px 3px 6px rgb(0 0 0 / 10%);
    --box-shadow-2: 0px 3px 18px rgb(0 0 0 / 20%);
    --primary-box-shadow: 0 0 0 0.2rem rgb(219 42 66 / 25%);
  
    /* Variants */ 
    --white-color: rgb(255, 255, 255);
    --black-color: rgb(0, 0, 0);
    --medium-grey-color: rgb(110, 110, 110);
    --bright-color: rgb(245, 157, 43);
  
  }
  
  /* Helper classes */
  .mlr5 {margin-left: 5px; margin-right: 5px;}
  .mt20 {margin-top: 20px;}
  .mb0 {margin-bottom: 0;}
  .pl0 {padding-left: 0 !important;}
  .pdg-bot30 {padding-bottom: 30px;}
  .min-w100 {min-width: 100px;}
  
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }
  
  /* Google Font - Poppins */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
  /* Google Font - Roboto */
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
  /* Fontawesome*/
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
  /* Body */
  /* ---------------------------------------------------------------------- */
body {
    font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 16px;
    color: var(--on-surface-color);
    background-color: var(--surface-color);
  }
  /* Header */
  /* ---------------------------------------------------------------------- */
  
  .MeatShop .container-fluid {
    padding-right: 0;
    padding-left: 0;
}


.MeatShop .header-top {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    background-color: var(--primary-color);
  }
  .MeatShop .login-right-wrapper {
    display: flex;
  }
.MeatShop .header-top .contact-wrapper {
    display: flex;
    color: var(--white-color);
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px;
  }
  .MeatShop a.tel-num {
    color: var(--white-color);
  }
  .MeatShop a.tel-num:hover {
    color: var(--white-color);
    text-decoration: none;
  }
  .MeatShop a.shopping-cart {
    font-size: 22px;
    height: 50px;
    color: var(--white-color);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
  }
  .MeatShop a.shopping-cart:hover {
      transform: translateY(1px);
  }
  .MeatShop .shopping-cart .cart-count {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    line-height: 19px;
    text-align: center;
    color: var(--primary-color);
    background-color: var(--white-color);
    border: 2px solid;
    border-color: var(--primary-color);
  }
  .MeatShop a.login-register {
    color: var(--white-color);
    height: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: .5rem;
  }
  .MeatShop a.login-register:hover {
    transform: translateY(1px);
    text-decoration: none;
  } 
  .MeatShop .login-register .user-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--primary-color);
    background-color: var(--white-color);
    display: inline-block;
    line-height: 30px;
    text-align: center;
  }
  .MeatShop .login-register .user-name {display: none;}
  .MeatShop .login-right-wrapper .drop-down-icon-hide::after{display: none;}
  .MeatShop .login-right-wrapper .dropdown-menu.show {
      margin-top: 3px;
      border-radius: 0;
      padding: 5px 0;
      border: 0;
    font-size: 14px;
    box-shadow: var(--box-shadow-1);
  }
  .MeatShop .login-right-wrapper .dropdown-menu.show::after {
    border-bottom: 8px solid;
    border-color: var(--white-color);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top:-7px;
    content: "";
    display: inline-block;
    right:8px;
    position: absolute;
    z-index: 1001; 
  }
  .MeatShop .loggedin-user {
    padding: 5px 10px;
    text-align: center;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .MeatShop .hr-sep {
    display: flex;
    justify-content: center;
  }
  .MeatShop .hr-sep hr {
    border-color: var(--neutral-color-6);
    margin: 5px 0;
    width: 75%;
  }
  .MeatShop .dropdown-item {
    color: var(--on-surface-color);
  }
  .MeatShop .dropdown-item.active, .MeatShop .dropdown-item:active {
    color: var(--primary-color);
    text-decoration: none;
    background-color: var(--white-color);
  }
  .MeatShop .dropdown-item:focus, .MeatShop .dropdown-item:hover {
    color: var(--on-surface-color);
    text-decoration: none;
    background-color: var(--primary-color-opacity-35);
  }
  .MeatShop .user-actions i {margin-right: 10px;}
  .MeatShop .view-cart-wrapper {
    width: 260px;
    padding: 10px;
  }
  .MeatShop .view-cart-wrapper .cart-item {
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    border-color: var(--border-color-1);
  }
  .MeatShop .view-cart-wrapper .cart-item .cart-item-image {
    margin-right: 10px;
  }
  .MeatShop .view-cart-wrapper .cart-item .cart-item-image .cart-img-thumb {
    width: 50px;
    height: auto;
  }
  .MeatShop .view-cart-wrapper .cart-item .cart-item-text {
    width: 100%;
  }
  .MeatShop .view-cart-wrapper .cart-item .cart-item-text p {
    font-size: 14px;
    margin-bottom: 0;
    color: var(--primary-color);
  }
  .MeatShop .view-cart-wrapper .cart-item .cart-item-text .cart-item-qty {
    display: flex;
    justify-content: space-between;
  }
  .MeatShop .view-cart-wrapper .cart-sub-total {
    margin-bottom: 0;
    padding-top: 10px;
    font-weight: 500;
    display: flex;
    justify-content: end;
  }
  .MeatShop .view-cart-wrapper .cart-sub-total span {
    margin-right: 10px;
  }
  .MeatShop .view-cart-wrapper .checkout-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: var(--white-color);
    background-color: var(--primary-color);
    padding: 8px 16px;
    border: 1px solid;
    border-color: var(--primary-color);
    border-radius: 3px;
    width: auto;
    height: 30px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .MeatShop .view-cart-wrapper .checkout-btn:hover {
    background-color: var(--primary-color-opacity-85);
  }
  .MeatShop .view-cart-wrapper .view-cart-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: var(--primary-color);
    background-color: var(--white-color);
    border: 1px solid;
    border-color: var(--neutral-color-5);
    padding: 8px 16px;
    border-radius: 3px;
    width: auto;
    height: 30px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .MeatShop .view-cart-wrapper .view-cart-btn:hover {
    color: var(--primary-color-opacity-85);
  }
  .MeatShop .search-wrapper {
    padding: 0 20px 10px 20px;
    display: flex;
    justify-content: center;
    background-color: var(--white-color);
  }
  .MeatShop .search-wrapper .form-wrapper {
    width: 100%;
  }
  .MeatShop .search-wrapper form input {
    border-color: var(--primary-color);
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  /* mobile navigation over */
  .MeatShop .navbar-collapse {
      position: absolute;
    background-color: var(--white-color);
    width: 100%;
    top: 44px;
    left: 0;
    padding-left: 20px;
    z-index: 100;
  }
  .MeatShop .ico-chev::after {margin-left: 10px;}
  .MeatShop .nav-menu {
    border-radius: 0;
    box-shadow: var(--box-shadow-1);
  }
  .MeatShop .navbar-nav.m-app-nav {margin-top: 20px;}
  .MeatShop .navbar-brand .brand-logo {height: 30px;}
  .MeatShop .navbar-toggler {
    border: 0;
    font-size: 24px;
  }
  .MeatShop .navbar-light .navbar-toggler i.fa-bars {
    color: var(--primary-color);
  }
  .MeatShop .navbar-light .navbar-toggler i.fa-times {
    color: var(--primary-color);
  }
  .MeatShop .navbar-brand {margin-right: 40px;}
  .MeatShop .navbar-light .navbar-nav .nav-link {
    color: var(--on-surface-color);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 16px 16px 0;
  }
  .MeatShop .navbar-light .navbar-nav .nav-link:hover {
    text-decoration: none; 
    color: var(--primary-color);
    opacity: 0.85;
  }
  .MeatShop .mob-ddl {margin-right: 20px;}
  .MeatShop .navbar-light .navbar-nav .nav-link.active {color: var(--primary-color);}
  .MeatShop .navbar-light .navbar-nav .nav-link:active, 
  .MeatShop .navbar-light .navbar-nav .nav-link:focus {color: var(--primary-color);}
  .MeatShop .opt-btns {
    display: flex;
    align-items: center;
  }
  /* Hero Banner */
  /* ---------------------------------------------------------------------- */
  .MeatShop .hero-banner  {
    background-image: linear-gradient(
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.35)), 
    url("../images/hero-img.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .MeatShop .hero-banner h1, h3 {
    color: var(--white-color);
    text-align: center;
  }
  .MeatShop .hero-banner h1 {
    font-weight: 700; 
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 3px;
    word-spacing: 10px;
    text-shadow: -1px -1px 6px rgb(0 0 0 / 84%);
  }
  .MeatShop .hero-banner h3 {
    color: var(--white-color);
    text-align: center;
    font-weight: 600;
    text-transform: none;
  }
  .MeatShop .hero-text-wrapper {
    background-color: var(--hero-transparent-bg);
    padding: 2rem;
    margin: 2rem;
    border-radius: 8px;
  }
  
  /* Hero Other Pages */
  /* ---------------------------------------------------------------------- */
  .MeatShop .hero-banner-inner {
    background-image: linear-gradient(
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.35)), 
    url("../images/inner-hero.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .MeatShop .hero-banner-inner h1 {
    color: var(--white-color);
    font-weight: 700; 
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 3px;
    word-spacing: 10px;
    text-shadow: -1px -1px 6px rgb(0 0 0 / 84%);
  }
  
  /* Site Breadcrumb */
  /* ---------------------------------------------------------------------- */
  .MeatShop .breadcrumb-container {
    padding: 20px;
  }
  .MeatShop .breadcrumb-in ul.site-breadcrumb {
      margin-bottom: 0;
      padding:0;
      list-style:none;
  }
  .MeatShop .breadcrumb-in ul.site-breadcrumb li {
      display: inline-block;
      padding-right: 20px;
      position: relative;
    font-size: 14px;
  }
  .MeatShop .breadcrumb-in ul.site-breadcrumb li a {
    color: var(--on-surface-color);
      font-size: 14px;
      text-decoration: none;
  }
  .MeatShop .breadcrumb-in ul.site-breadcrumb li a:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
     text-underline-offset: 3px;
  }
  .MeatShop .breadcrumb-in ul.site-breadcrumb .current {
    color: var(--primary-color);
  }
  .MeatShop .breadcrumb-in ul.site-breadcrumb li::after {
    content: '>';
    font-size: 14px;
    position: absolute;
    right: 6px;
    top: 1px;
  }
  .MeatShop .breadcrumb-in ul.site-breadcrumb li:last-child::after {content: '';}
  
  /* Product Categories */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .section {
    padding-top: 30px;
    background-color: var(--white-color);
  }
  .MeatShop .section.products {
    padding: 30px 0;
    background-color: var(--primary-color);
    background-image: linear-gradient( 
      rgba(0,0,0,0.0), 
      rgba(0,0,0, 0.35)), 
      url("../images/prd_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .MeatShop h2 {
    text-align: center;
    word-spacing: 3px;
    margin-bottom: 30px;
  } 
  .MeatShop .qty-wrapper {background-color: var(--neutral-color-6); height: 30px;}
  .MeatShop .prd-item img {width: 100%;}
  .MeatShop .prd-item {
    margin-bottom: 30px;
    text-align: center;
  }
  .MeatShop .prd-item .prd-item-link {
    background-color: var(--white-color);
    text-decoration: none;
    display: inline-block;
    width: 100%;
  }
  .MeatShop .prd-item .prd-item-link img {margin-bottom: 4px;}
  .MeatShop .prd-count-inner {
    display: flex;
    justify-content: space-between;
  }
  .MeatShop .prd-count-inner.sing-btn {justify-content: end;}
  .MeatShop .prd-count-inner button {
    font-size: 12px;
    padding: 5px 10px;
    background-color: var(--neutral-color-6);
    border: 0;
    width: 40px;
    height: 30px;
  }
  .MeatShop .icon-sub, .icon-add {
    font-size: 12px;
    padding: 5px 10px;
    background-color: var(--neutral-color-6);
    border: 0;
    width: 40px;
    height: 30px;
  }
  .MeatShop .qty-no input {
    width: 50px;
    height: 30px;
    border: 1px solid;
    border-color: var(--neutral-color-5);
    background-color: var(--white-color);
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }
  
  /* remove scroll in number input */
  .MeatShop .qty-no input[type=number]::-webkit-inner-spin-button, 
  .MeatShop .qty-no input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  .MeatShop .prd-count-inner button.add-btn {
    font-size: 14px;
    font-weight: 500;
    color: var(--white-color);
    background-color: var(--primary-color);
    padding: 5px 8px;
    border: 0;
    width: auto;
    height: 30px;
  }
  .MeatShop .prd-count-inner button.add-btn:hover {
    background-color: var(--primary-color-opacity-85);
  }
  .MeatShop .section.products .prd-item .prd-item-link:hover {
    transform: translateY(1px);
  }
  .MeatShop a.prd-item-link:hover {
    text-decoration: none;
  }
  .MeatShop .prd-item .prd-item-text h5 {
    font-size: 16px;
    color: var(--on-surface-color);
    margin-bottom: 0;
    padding: 8px 0;
    text-transform: uppercase;
  }
  .MeatShop .bdr-bot {
    border-bottom: 1px solid;
    border-color: var(--neutral-color-6);
  }
  .MeatShop .header-wrapper {
    display: flex;
    flex-direction: column;
  }
  .MeatShop .section.products .header-wrapper h2 {color: var(--white-color);}
  /* Product Carousel Styles */
  .MeatShop .section .owl-theme .owl-dots .owl-dot.active span, .MeatShop .section .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--primary-color);
  }
  .MeatShop .owl-theme .owl-nav [class*='owl-']:hover {
    background-color: var(--primary-color) !important;
  }
  .MeatShop .owl-dots {margin-top: 10px;}
  .MeatShop .owl-nav i {font-size: 14px;}
  
  
  .MeatShop .section.products button {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-radius: 0;
    font-weight: 500;
    margin-top: 20px;
  }
  .MeatShop .section.products button:hover {
    background-color: var(--primary-color-opacity-85);
  }
  .MeatShop .section.products button span i {
    margin-left: 10px;
    font-size: 14px;
  }
  /* Product Range */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .section.productRange {
    background-color: var(--white-color);
  }
  .MeatShop .section.productlisting {
    background-color: var(--white-color);
  }
  .MeatShop .item-count {
    background-color: var(--neutral-color-6);
    padding: 15px;
    margin-bottom: 20px;
  }
  .MeatShop .item-count h6 {margin-bottom: 0;}
  
  .MeatShop .section.productlisting {
    background-color: var(--white-color);
    padding-top: 10px;
  }
  .MeatShop .items-list a {
    padding: 8px 12px;
    color: var(--on-surface-color);
  }
  .MeatShop .items-list a:hover {
    background-color: var(--primary-color-opacity-35);
  }
  .MeatShop .items-list a.active {
    background-color: var(--primary-color);
    border: 0;
  }
  .MeatShop .mbl-prd-cat {
    margin-bottom: 20px;
  }
  .MeatShop .mbl-prd-cat .btn-cat {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
  }
  .MeatShop .mbl-prd-cat .dropdown-menu button {
    background-color: var(--white-color);
  }
  .MeatShop .mh-at {min-height: auto !important; text-align: center !important;}
  
  .MeatShop .load-more {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 20px 0;
  }
  
  .MeatShop .load-more a.load-more-link {
    color: var(--primary-color);
    margin-bottom: 0;
    padding: 10px 20px;
    width: 200px;
  }
  .MeatShop .load-more a.load-more-link:hover {
    text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
  }
  /* Product Details */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .pro-img-wrapper {
    display: flex;
    flex-direction: column;
  }
  .MeatShop .pro-img-wrapper .prd-img-large img {
    border: 1px solid;
    border-color: var(--neutral-color-5);
    margin-bottom: 10px;
  }
  .MeatShop .pro-img-wrapper .prd-img-thumb {display: flex;}
  .MeatShop .pro-img-wrapper .prd-img-thumb img {
    max-width: 100px;
    height: auto;
    margin-right: 10px;
    border: 1px solid;
    border-color: var(--neutral-color-5);
    opacity: .75;
  }
  .MeatShop .pro-img-wrapper .prd-img-thumb img.img-active {
    border-color: var(--primary-color);
    opacity: 1;
  }
  .MeatShop .prd-details-text {margin-top: 20px;}
  .MeatShop .prd-details-text .prd-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
  }
  .MeatShop .prd-details-text .prd-more-details p {margin-bottom: 10px;}
  .MeatShop .prd-details-text .prd-price {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 20px;
  }
  .MeatShop .prd-count-inner.p-det {justify-content: flex-start;}
  .MeatShop .prd-count-inner .select-weight {width:60%; margin-right: 20px;}
  .MeatShop .select-qty {display: inline-block;}
  .MeatShop .select-qty p {margin-bottom: 8px;}
  .MeatShop .select-qty .qty-wrapper {height: 38px;}
  .MeatShop .select-qty .qty-wrapper .a-fix {
      height: 38px;
 
  }
  .MeatShop .ft-bld {font-weight: 500;}
  .MeatShop .qty-select-details p {font-weight: 500;}
  .MeatShop .prd-buttons {margin-top: 30px;}
  .MeatShop .prd-buttons .prd-add {
    border: 2px solid;
    border-color: var(--primary-color);
    color: var(--primary-color);
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .MeatShop .prd-buttons .prd-add:hover {text-decoration: none;}
  .MeatShop .prd-buttons .prd-checkout {
    border: 2px solid;
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: var(--white-color);
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 0;
    text-align: center;

  }
  .MeatShop .prd-buttons .prd-checkout:hover {
    text-decoration: none;
    background: var(--primary-color-opacity-85);
  }
  .MeatShop .comm-details {margin-top: 50px; margin-bottom: 20px;}
  
  /* Accordion */
  .MeatShop .accordion .card {
      border-radius: 0;
      border-top:0;
    background-color: var(--neutral-color-6);
      margin-bottom: 3px;
  }
  .MeatShop .accordion .card-header {
    background-color: var(--neutral-color-5);
      cursor: pointer;
      padding: 0;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      border-radius: 0;
  }
  .MeatShop .accordion .card-header button {
      margin: 0;
      padding: 10px 15px 10px;
      font-size: 14px;
    font-weight: 600;
    color: var(--neutral-color-3);
      display: block;
  }
  .MeatShop .accordion .card-header button:focus {outline: 0; box-shadow: none; text-decoration: none;}
  .MeatShop .accordion .card-header .btn-link:hover  {text-decoration: none;}
  .MeatShop .accordion .card-header button:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: '\f068';
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 12px;
  }
  .MeatShop .accordion .card-header button.collapsed:after {
      content: '\f067';
  }
  /* Product Checkout */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .sp-cart .search-wrapper {display: none;}
  .MeatShop .container.checkout {padding-top: 60px; padding-bottom: 60px;}
  .MeatShop .container.checkout .header-wrapper h2 {margin-bottom: 60px;}
  .MeatShop .checkout .table-responsive {margin-bottom: 30px;}
  .MeatShop .checkout .table {font-size: 14px;}
  .MeatShop .checkout .table tr th {font-weight: 600;}
  .MeatShop .checkout .prd-item-wrap {
    display: flex;
    width: 360px;
  }
  .MeatShop .checkout .img-small {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }
  .MeatShop .checkout .prd-item-name {
    font-weight: 400;
  }
  .MeatShop .checkout .select-qty {min-width: 150px;}
  .MeatShop .checkout .qty-wrapper {display: inline-block;}
  
  
  .MeatShop .cart-details {display: flex; margin-bottom: 30px;}
  .MeatShop .cart-details .align-option {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .MeatShop .cart-details .cart-sub-total .st-item {
      min-width: 165px;
      padding: 10px;
      border: 1px solid #eee;
  }
  .MeatShop .mr10 {margin-right: 10px;}
  .MeatShop .mr20 {margin-right: 20px;}
  
  .MeatShop .cart-details .st-item {font-weight: 500;}
  .MeatShop .cart-details .total-to-pay .st-item {font-size: 16px; font-weight: 700;}
  
  .MeatShop .cart-details .cart-button-wrapper {
      margin-top: 30px;
      display:flex;
      flex-direction: column;
  }
  .MeatShop .cart-details .cart-button-wrapper a:first-child {margin-bottom: 15px;}
  
  .MeatShop .cart-item-count {margin-bottom: 10px;}
  .MeatShop .cart-badge {
    width: 30px;
      height: 30px;
      border-radius: 15px;
      line-height: 24px;
      background-color: var(--primary-color);
      color: var(--white-color);
  }
  .MeatShop .cart-buttons {
    display: flex;
    flex-direction: column;
    align-items: self-end;
    margin-top: 30px;
  }
  .MeatShop .cart-buttons a {
    text-align: center;
    padding: 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
  }
  .MeatShop .cart-buttons a.crt-btn-checkout {
    border: 2px solid;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--white-color);
    margin-top: 15px;
  }
  .MeatShop .cart-buttons a.crt-btn-cont {
    border: 2px solid;
    border-color: var(--primary-color);
    background-color: var(--white-color);
    color: var(--primary-color);
  }
  .MeatShop .cart-buttons a.crt-btn-checkout:hover {
    background-color: var(--primary-color-opacity-85);
  
  }
  
  /* Best Sellers */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .prd-item {
    background-color: var(--white-color);
    border: 1px solid rgba(242, 235, 235, 0.45);
    box-shadow: var(--box-shadow-1);
    padding: 12px;   
  }
  .MeatShop .prd-item:hover {
    border: 1px solid;
    border-color: var(--primary-color);
  }
  .MeatShop .prd-item img {
    height: 230px;
  }
  .MeatShop .prd-item .prd-item-text {
    text-align: left;
    min-height: 90px;
  }
  .MeatShop .prd-item .prd-item-text h5 {
    font-weight: 400;
    text-transform: none;
  }
  .MeatShop .prd-item .prd-item-text h6 {
    font-size: 16px; 
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 8px;
  }
  /* Testimonial */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .section.testimonials {
    padding: 30px 0;
    background-color: var(--primary-color);
  }
  .MeatShop .section.testimonials .header-wrapper h2 {color: var(--white-color);}
  .MeatShop .testimonial-wrapper {
    background-color: var(--white-color);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 1px solid rgba(242, 235, 235, 0.45);
    box-shadow: var(--box-shadow-1);
  }
  .MeatShop .testimonial-header {display: flex;}
  .MeatShop .avt-img {margin-right: 10px;}
  .MeatShop .avt-img-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  .MeatShop .avt-img-name h6 {
    margin-bottom: 3px;
  }
  .MeatShop .ratings {
    font-size: 11px;
    color: var(--bright-color);
  }
  .MeatShop .ratings i {margin: 0 1px;}
  .MeatShop .testimonial-body p {
    margin-bottom: 0;
    padding-top: 20px;
    text-align: left;
  }
  /* Promotions */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .section.promotions {
    padding: 0;
    margin-top: 30px;
  }
  .MeatShop .promo-wrapper {
    background-color: var(--neutral-color-6);
    height: 220px;
  }
  .MeatShop .promo-text-wrapper {
    display: flex;
    align-items: center;
    height: 220px;
  }
  .MeatShop .promo-text {
    padding: 20px;
    text-align: left;
  }
  .MeatShop .promo-text h5 {margin-bottom: 16px;}
  .MeatShop .form-wrapper button {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; 
    font-weight: 500;
  }
  .MeatShop .form-wrapper button:hover {
    color: var(--white-color);
    background-color: var(--primary-color-opacity-85);
  }
  .MeatShop .form-control:focus {
    background-color: var(--white-color);
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: var(--primary-box-shadow);
  }
  .MeatShop .btn.focus, .MeatShop .btn:focus {
    outline: 0;
    box-shadow: var(--primary-box-shadow);
  }
  .MeatShop .email-small-text {
    margin-bottom: 0;
    margin-top: 16px;
    font-size: 12px;
  }
  .MeatShop .promo-bg-img {
      background-image: url("../images/promo-image.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 220px;
      display: none;
  }
  /* Our Blog */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .section.blog img {width: 100%;}
  .MeatShop .section.blog .prd-item-text {
    text-align: left;
    padding: 10px 0;
  }
  .MeatShop .section.blog h6 {
    color: var(--neutral-color-4);
    font-size: 14px;
  }
  .MeatShop .section.blog button {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-radius: 0;
    font-weight: 500;
  }
  .MeatShop .section.blog button:hover {
    background-color: var(--primary-color-opacity-85);
  }
  .MeatShop .section.blog button span i {
    margin-left: 10px;
    font-size: 14px;
  }
  .MeatShop .section.blog h4 {font-size: 20px;}
  /* Footer */
  /* ---------------------------------------------------------------------- */ 
  .MeatShop .pg-footer {
    margin-top: 30px;
    padding: 30px 0 70px 0;
    background-color: var(--primary-color);
    position: relative;
  }
  .MeatShop .pg-footer .brand-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .MeatShop .pg-footer .brand-wrapper .logo-wrap {margin-bottom: 10px;}
  .MeatShop .pg-footer .brand-wrapper .addr-wrap h6 {
    font-size: 13px;
    color: var(--white-color);
    text-transform: uppercase;
  }
  .MeatShop .pg-footer .brand-wrapper .addr-wrap .addr-lines span {
    color: var(--white-color);
    font-size: 12px;
    display: block;
    letter-spacing: 1px;
  }
  .MeatShop .pg-footer .social-icons a {
    font-size: 20px;
    display: inline-block;
    color: var(--white-color);
    padding: 5px 0;
    margin-right: 30px;
  }
  .MeatShop .pg-footer .social-icons a:hover {transform: translateY(1px);}
  .MeatShop .pg-footer .social-icons a i {font-size: 24px;}
  .MeatShop .pg-footer .pay_cards {display: flex; margin-top: 10px;}
  .MeatShop .pg-footer .payment-icons span {
    padding: 8px;
    display: inline-block;
  }
  .MeatShop .pg-footer .payment-icons span img {
     height: 30px;
  }
  .MeatShop .pg-footer .copy {
    font-size: 10px;
    color: var(--white-color);
    text-align: left;
    letter-spacing: .5px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .MeatShop .pg-footer .copy .copy-text {padding-right: 10px; margin-bottom: 5px;}
  .MeatShop .pg-footer .copy a {
    color: var(--white-color);
  }
  .MeatShop .pg-footer .copy .copy-sep {margin: 0 10px;}
  .MeatShop .pg-footer .copy a:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }
  
  .MeatShop .pg-footer .help {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
  .MeatShop .pg-footer .help .help-cover {
    display: inline-block;
    color: var(--white-color);
    background-color: var(--black-color);
    padding: 8px 16px;
    height: 40px;
    border-radius: 20px;
  }
  .MeatShop .pg-footer .help .help-cover > * {display: inline-block;}
  .MeatShop .pg-footer .help .help-cover P {
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 500;
  }
  .MeatShop .pg-footer .top-up  {
    position: absolute;
    top: -20px;
    bottom: 0;
    right: 15px;
  }
  .MeatShop .pg-footer .top-up .icon-up {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: var(--black-color);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-1);
  }
  .MeatShop .pg-footer .top-up .icon-up i { 
    font-size: 20px;
    color: var(--white-color);
  }
  
  /* ====================================================================== */
  /* Responsive Media Query */
  /* ====================================================================== */
  @media (min-width: 576px) {
    .MeatShop .search-wrapper .form-wrapper {
      width: 80%;
    }
    .MeatShop .pg-footer .copy {flex-direction: row;}
  }
  /* ----------------------------------------------------------------------- */
  @media (min-width: 768px) {
    .MeatShop .header-top {justify-content: flex-end;}
    .MeatShop .mob-ddl {margin-right: 0;}
    .MeatShop .login-register .user-icon {margin-right: 5px;}
  
    .MeatShop .navbar-collapse {
      position: relative;
      width: auto;
      top: unset;
      left: unset;
      padding-left: 0;
      z-index: 1;
    }
  
    .MeatShop .search-wrapper {padding-bottom: 30px;}
    .MeatShop .search-wrapper .form-wrapper {width: 70%;}
    .MeatShop .login-register .user-name {display: block;}
    .MeatShop .section {padding-top: 60px;}
    .MeatShop .owl-nav {margin-top: 0 !important;}
  
    .MeatShop .pg-footer .help {bottom: 0;top: -20px;}
    .MeatShop .pg-footer .top-up  {top: -70px;}
    .MeatShop .pg-footer .top-up .icon-up {box-shadow: var(--box-shadow-2); }
    .MeatShop .prd-details-text {margin-top: 0;}
    .MeatShop .comm-details {margin-top: 50px; margin-bottom: 0;}
  
  
  
    .MeatShop .cart-buttons {flex-direction: row;}
    .MeatShop .cart-buttons a.crt-btn-cont {margin-right: 15px;}
    .MeatShop .cart-buttons a.crt-btn-checkout {margin-left: 15px;}
    
  }
  /* ----------------------------------------------------------------------- */
  @media (min-width: 992px) {
    .MeatShop .navbar {padding: 30px 0;}
    .MeatShop .navbar-light .navbar-nav .nav-link {padding: 8px 16px; font-size: 16px;}
    .MeatShop .navbar-nav.m-app-nav {margin-top: 0;}
    .MeatShop .section.products {padding: 100px 0;}
    .MeatShop .section.testimonials {padding: 100px 0;}
    .MeatShop .section.promotions {padding: 0; margin-top: 60px;}
    .MeatShop .promo-bg-img {display: block;}
    .MeatShop .pg-footer {margin-top: 60px; padding: 60px 0 60px 0;}
    .MeatShop .pg-footer .brand-wrapper {flex-direction: row;}
    .MeatShop .pg-footer .brand-wrapper .logo-wrap {margin-bottom: 0;margin-right: 10px;}
    .MeatShop .section.blog .in-text-wrapper {min-height: 215px;}
    .MeatShop .pg-footer .pay_cards {margin-top: 0;}
    .MeatShop .search-wrapper .form-wrapper {width: 60%;}
  
  
    .MeatShop .prd-buttons .prd-add, .prd-buttons .prd-checkout {width: 47%;}
    .MeatShop .prd-buttons .prd-add {margin-right: 20px;}
    .MeatShop .checkout .table {font-size: 16px;}
  
  
    .MeatShop .cart-buttons a {width: 260px;}
  }
  /* ----------------------------------------------------------------------- */
  @media (min-width: 1200px) {
    .MeatShop .search-wrapper .form-wrapper {
      width: 50%;
    }
    .MeatShop .mob-order {order: 2;}
  }
  /* ----------------------------------------------------------------------- */

  .MeatShop .help-cover p{
    margin-top: 0;
  }