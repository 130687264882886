/* The container */
.Default .opacity03 label{
  color: #1a381a !important;
  font-weight: 600  !important;
}
.Default .opacity03 .ant-checkbox-inner{

  background:#1a381a !important;
}

.Default .ReadMore, .ReadLess{
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}
.Default .ReadMore li:nth-child(n+8) {
  display:none;
}
.Default .ReadLess li:nth-child(n+8) {
  display:block;

}

.Default .formatColour .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Default .formatColour .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 20px;
}

/* Create a custom checkbox */
.Default .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 45px;
  width: 45px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.Default .formatColour .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.Default .formatColour .container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Default .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Default .formatColour .container input:checked ~ .checkmark:after {
  display: block;
}
.Default .hidden-xs .ant-menu-submenu-title{
  padding-left: 0px !important;
}
/* Style the checkmark/indicator */
.Default .container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.Default .formatColour{
  display: inline-flex;
  height: 37px;
}

.Default .image-gallery.fullscreen-modal .image-gallery-content {
  width: 80% !important;
  height: 100%!important;
  left:7% !important;
}
.Default .bg-light {
    background-color: #191529!important;
}
.Default .navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255) !important;
}
.Default .navbar-light .navbar-nav .nav-link:hover {
    color: #dcae0a;
}
.Default .topleftbanner{
    padding-right: 0;
}
.Default .torightbanner{
    padding-left: 0;
}
.Default .mainbanner{
    padding: 0 3px 0 3px;
}
.Default .footer{
    background-color: #393939!important;
    color:  #e6e6e6;
    padding-top: 48px;
}
.Default .newsletter-subscribe{
    background-color: #f5f5f5!important;
    color: rgb(0, 0, 0);
    padding: 15px;
}
.Default .footer h5{
    padding: 0 0 16px;
    font-family: MontrealSerial-Bold, sans-serif;
    font-size: 14px;
    cursor: auto;
    color: #fff;
}
.Default .navbar-toggler-icon{
  filter: invert(100%);
}
.Default h2{
    font-family: MontrealSerial-Bold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    background: url(../images/slider-bar.png) right no-repeat;
}
.Default .linkGroup_3qKNM ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    list-style: none;
}
.Default .linkGroup_3qKNM li {
    margin: 0;
    padding: 0 0 16px;
    line-height: 1;
}
.Default .linkGroup_3qKNM li a{
    color:  #e6e6e6;
    font-family: MontrealSerial-Bold, sans-serif;
    font-size: 12px;
}
.Default .Nav-banner{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.Default .css-1jvu78r {
    font-size: 14px;
    display: inherit;
    min-width: 0px;
    margin-left: 0.5em;
}
.Default .mini-cart-total-label sup{
    background: #ff0000;
    color: #fff;
    padding: 2px 8px;
    border-radius: 50%;
    font-size: 14px;
    top: 0em;
}
.Default .card svg{
    color: rgb(165, 165, 165);
    float: right;
    margin: 2px;
}
.Default .card span svg{
  color: rgb(165, 165, 165);
  float: none;
  margin: 2px;
}
.Default .copyright{
    padding: 16px 0 16px 0;
    text-align: center;
}

.Default .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: auto;
    text-align: center;
    font-family: arial;
    min-height: 600px;
  }
  .Default .card.details {
    min-height: 100px;
  }
  .Default .card input{
    width:100%;
    height: 46px;
    text-align: center;
    font-size: 18px !important;  
  }
  .Default .react-numeric-input{
    width:30%;
    height: 46px;
    background-color:#e6e6e6;
}
.Default .inputandbtn{

    text-align: center;
}
.Default .card button{
    color:  #000 !important;
    font-family: montrealserial-regular,sans-serif !important;
    width: 60% !important;
    background:linear-gradient(to bottom, #fdbb15 0%,#fd9e0f 100%) ;
    font-size: 15px !important;  
    margin-top: 10px;
    background: #fdbb15;background: -moz-linear-gradient(top, #fdbb15 0%, #fd9e0f 100%);
    background: -webkit-linear-gradient(top, #fdbb15 0%,#fd9e0f 100%);
    background: linear-gradient(to bottom, #fdbb15 0%,#fd9e0f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdbb15', endColorstr='#fd9e0f',GradientType=0 );
    display: inline-block;color:#000;
    text-decoration: none;
     border-radius: 3px; 
     padding: 6px 12px;
     border:none;
  }
  .Default .card button:hover{ background: #fd9f0f !important;
    background: -moz-linear-gradient(top, #fd9f0f 0%, #fdbb15 100%) !important;
    background: -webkit-linear-gradient(top, #fd9f0f 0%,#fdbb15 100%)  !important;
    background: linear-gradient(to bottom, #fd9f0f 0%,#fdbb15 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd9f0f', endColorstr='#fdbb15',GradientType=0 ) !important;
  }
  .Default .card button.notify{
    font-family: montrealserial-regular,sans-serif !important;
      width: 60% !important;
      background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #00bdeb 0%, #009fe1 100%) repeat scroll 0 0 !important;
      font-size: 15px !important;  
      margin-top: 10px;
  }

  .Default .card button svg{
      float: left;
      color: #fff!important;
  }
  .Default .divLoader{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .Default .cardtxt{
    font-family: montrealserial-regular,sans-serif !important;
    font-size: 14px;
  }
  .Default .card img{
      margin: 15px 5px 0 5px;
      width: 95%;
      max-height: 300px;
  }
  .Default .card .prductimgbg{
    margin: 10px !important; 
  }
  .Default .card h4{
    font-family: montrealserial-regular,sans-serif;
    padding-top:10px;
    font-weight: bold;
    font-size: 15px;  
  }
  .Default .paymenttype{
  width: 100% !important;
}
.Default .fullscreen .image-gallery-slides img{
  width: 65% !important;
}
.Default .deliverymessage{
  background-color: #004664;
  color:#FFF;
}
.Default .lazyload-wrapper .card h4{
  line-height: 1.5em;
  height: 5em;        
}
.Default .searchbox{
  margin-bottom: 0!important;
}
.Default .priceright{
      margin-top: 18px;;
  }
  .Default .price {
    color: #39464e;
    font-size: 48px;
    margin: 0;
  }
  .Default .price sup {
    color: #39464e;
    font-size: 15px;
  }
  .Default .price sup.pricex {
    color: #39464e;
    font-size: 12px;
  }
  .Default .disprice {
    color: #c20e1a;
    font-size: 48px;
  }
  .Default .disprice sup {
    color: #c20e1a;
    font-size: 15px;
  }
  .Default .disprice sup.pricex {
    color: #c20e1a;
    font-size: 12px;
  }
  .Default .dispricetxt{
    color: #c20e1a;
  }
  .Default .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  .Default .card button:hover {
    opacity: 0.7;
  }
  .Default .homeMessage{
    font-family: montrealserial-regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .05em;
    font-size: 12px;
    line-height: 1.385;
  }
  .Default .homeh2Message{
    font-family: montrealserial-regular,sans-serif;
    letter-spacing: 0.5px;
    font-size: 20px;
    line-height: 1.15;
    margin-top: 25px;
  }
  .Default .accleft{
    padding-right: 0px;
  }
  .Default .accright{
    padding-left: 0px;
  }
  .Default .col-sm-3 .ant-menu {
    width:100% !important;
  }
  .Default .alert .ant-radio-group{
    width: 100%;
  }
  .Default .quantity{
    display:flex;
    width:160px;
 }
 
 /* it will support chrome and firefox */
 .Default .quantity input[type=number]::-webkit-inner-spin-button,
 .Default .quantity input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance:none;
 }
 
 .Default .quantity input,.Default .quantity button{
    width:40px;
    padding:.1em;
    text-align:center;
    font-weight:900;
    background:white;
    border:1px solid #aaa;
 }
 
 .Default .quantity input{
    border-left:none;
    border-right:none;
 }
 .Default .or-icon {
  padding-left: 110px !important;
  padding-top: 100px !important ;
 }
 .Default .ant-menu-submenu-title span{
  vertical-align: middle;
 }
 .Default .basketadded{
  font-family: montrealserial-regular,sans-serif;
  color:#52c41a;
 }
 .Default .basketadded svg{
   vertical-align: baseline;
 }
 .Default .main{
  padding-right: 15px;
  padding-left: 15px;
}
.Default .main > div > div{
  padding:0;
}
.Default .fmessagr{
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.Default ul.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.Default ul.pagination li {display: inline;}

.Default ul.pagination li{
  color: black;
  float: left;
  text-decoration: none;
  border-radius: 5px;
}

.Default ul.pagination li.active {
  background-color: #dcae0a;
  color: white;
  border-radius: 5px;
}

.Default ul.pagination li:hover:not(.active) {background-color: rgb(148, 148, 148);}

.Default ul.pagination li:not(.active) {background-color: rgb(223, 223, 223);margin: 1px;}

.Default ul.pagination li a{padding: 8px 16px; display: block;}

.Default .containerz { position:relative;width: 100%;}
 
.Default .overlayz {
    position:absolute;
    top:25%;
    left:25%;
    z-index:1;
}
.Default .overlayz h1{
  color: #FFF;
  text-align: center;
  font-size:4.1em;
}
.Default .overlayz h3{
  color: #f8e994;
  text-align: center;
  font-size:3.1em;
}
.Default video {
  width: 100%;
  max-height: 100%;
}
.Default .image-gallery-fullscreen-button, .Default .image-gallery-play-button {
  top: 0px !important;
  bottom:auto !important;
  padding: 20px;
}
.Default .carticon a svg{
  margin-top: 10px;
}
.Default .outofstock{
  padding: 10px;
  margin-top: 10px;
  color: #be0404;
  font-weight:bold;
  font-size: small;;
  background:#c5c5c5;
}
.Default .mulitistock{
  padding: 10px;
  margin-top: 10px;
  color: #FFF;
  font-weight:bold;
  font-size: small;;
  background:#0080bd;
}
.Default .SoFtr{
  margin-bottom: 20px;
}
.Default .currencysel{
  font-weight:bold;
}
.Default paragraph::after{
  content: "\a";
  white-space: pre;
}
.Default paragraph run{
  line-height: 30px;
}
.Default list listitem paragraph run{
  padding-left: 15px;
}
.Default list listitem paragraph run:before {
  content: "•"; /* Insert content that looks like bullets */
  padding-right: 8px;
  font-weight:bold;
}
.Default paragraph run[fontweight~="Bold"]{
  font-weight:bold;
  line-height: 56px;
}
.Default .ant-descriptions-title{
  margin-bottom: 0px !important; 
}
.Default #cartcount{
  font-weight: bold;
}
.Default .react-resizable{
  width: 100vw;
  max-height: 100vh;
  /* 16/9 = 1.778 */
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.Default .imageClass img{ 
  width: 100%;
  object-fit: fill 
}
.Default .react-multi-carousel-track  li div{
  padding-left: 3px;
}
.Default .multioffer{
    background-color: #d40000 !important;
    border: 13px solid #d40000 !important;
}
.Default .multioffer .ant-collapse-content {
   border-top: 0px solid #d9d9d9 !important; 
   border-bottom: 0px solid #d9d9d9 !important; 
}
.Default .multioffer .ant-collapse-header{
  font-size: larger !important;
    color: rgb(253, 178, 19)  !important;
    font-weight: 600 !important; 
}
.Default .multioffer.ant-collapse > .ant-collapse-item {
    border-bottom: 0px solid #d9d9d9  !important;  
}

@media screen and (max-width: 1200px) {
  .Default .overlayz {
    position:absolute;
    top:30%;
    left:30%;
    z-index:1;
}
.Default .overlayz h1{
    color: #FFF;
    text-align: center;
    font-size:3.1em;
  }
  .Default .overlayz h3{
    color: #f8e994;
    text-align: center;
    font-size:2.1em;
  }
}

@media screen and (max-width: 800px) {
  .Default .overlayz h1{
    color: #FFF;
    text-align: center;
    font-size:2.1em;
  }
  .Default .overlayz h3{
    color: #f8e994;
    text-align: center;
    font-size:1.1em;
  }
  .Default .ant-select {
    margin-bottom: 22px !important;
  }
  .Default .navbar{
    margin-top: 15px;
  }
  .Default .hidden-xs{
    display:none;
  }
  .Default .navbar-nav {
    display: contents;
  }
  .Default .inputandbtn button span, .Default .logout {
    display:none;
  }
  .Default .inputandbtn button {
    width: 50px !important;
    padding-top: 9px;
    padding-bottom: 18px;
  }
  .Default .react-numeric-input {
    width: 60% ;
    height: 46px;
    background-color: #e6e6e6;
}
.Default .ant-drawer{
  margin: auto;
}
.Default .ant-drawer-content-wrapper{
  width: 100% !important;
}
}
 
.Default .navbar .dropdown-menu  a{
  font-size: 14px !important;
}
.Default .custom-select, .Default .form-control{
  font-size: 1.3rem !important;
}
.Default .btn{
  font-size: 1.3rem !important;
}
.Default #bigImg{
  z-index: 99000;
}
.Default #bigImg div{
  width: 850px !important;
  height: auto !important;
  min-width: 100% !important;
}
.Default #bigImg img{
  height: auto !important;
  min-width: 80% !important;
}
.Default .layout div div
{
  padding-top: 10px;
}

.Default .ant-list-vertical .ant-list-item-meta {
   margin-bottom: 0px !important; 
}
.Default .layout div div {
  padding-top: 2px !important;
}
.Default .ant-tag{
  background: #ddd !important;
    color: #717171 !important;
    display: inline-block !important;
    padding: .5em .9em !important;
    margin: 0 0 0 .4rem !important;
    cursor: pointer;
    font-size: 17px !important;
    font-size: 1.7rem !important;
    font-weight: 700;
    vertical-align: top !important;
    border-radius: 2px !important;
}
.Default .ant-tag-checkable-checked{
  background: #005e6e  !important;
  color: #FFF !important;
}
.Default .articleimg{
  float: left;
  margin-right: 8px;
}